<template>
  <nav>
    <!-- app bar (big screens) -->
    <v-app-bar
      app
      height="61"
      color="white"
      class="app-bar-box-shadow d-none d-md-block"
      v-if="isAuthenticated"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>
      <v-img
        style="cursor: pointer"
        @click="goDashboard"
        max-width="122"
        :src="require('../assets/img/NavLogo.png')"
      >
      </v-img>
      <span
        v-if="isRouteAdmin"
        class="text-h6 font-weight-black primaryDark--text mt-1 ml-1"
        >- ADMIN</span
      >
      <v-spacer></v-spacer>
      <span class="text-subtitle-2 mr-5">{{ profile && profile.name }}</span>
      <v-toolbar-items v-if="isAuthenticated" class="hidden-sm-and-down">
        <profile-menu
          :items="navDropDownOptions"
          :isAdmin="isAdmin"
          :isSuperAdmin="isSuperAdmin"
          :isPendingInvitation="isPendingInvitation"
        ></profile-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-app-bar
      v-else
      app
      height="61"
      color="white"
      class="app-bar-box-shadow d-none d-md-block"
    >
      <v-img max-width="122" :src="require('../assets/img/NavLogo.png')">
      </v-img>
    </v-app-bar>
    <!-- END app bar (big screens) -->
    <!-- app bar user mobile -->
    <v-app-bar
      app
      height="61"
      color="white"
      class="app-bar-box-shadow d-block d-md-none"
      v-if="isAuthenticated"
    >
      <div class="d-flex align-center justify-space-between width100">
        <div class="d-flex align-center">
          <v-img
            @click="goDashboard"
            max-width="122"
            :src="require('../assets/img/NavLogo.png')"
            class="adjust-logo-margin"
          ></v-img>
          <span
            v-if="isRouteAdmin"
            class="text-subtitle-1 font-weight-black primaryDark--text mt-1 ml-1"
            >- ADMIN</span
          >
        </div>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="hidden-md-and-up"
        ></v-app-bar-nav-icon>
      </div>
    </v-app-bar>
    <v-app-bar
      v-else
      app
      height="61"
      color="white"
      class="app-bar-box-shadow d-block d-md-none"
    >
      <v-img max-width="122" :src="require('../assets/img/NavLogo.png')">
      </v-img>
    </v-app-bar>
    <!-- END app bar user mobile -->

    <!-- Start of mobile side menu -->
    <v-navigation-drawer app v-model="drawer" right class="drawer-class">
      <v-list>
        <v-list-item>
          <v-list-item-content
            class="d-flex align-center justify-center text-subtitle-2 secondaryDark2--text border-bottom"
          >
            {{ isRouteAdmin ? "ADMIN" : profile && profile.name }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="d-flex align-center"
          v-for="(item, index) in navDropDownOptions"
          v-if="item.show"
          :key="item.id"
          :to="item.path"
        >
          <v-list-item-icon class="mr-0 icon-margin-adjust">
            <v-img
              v-if="!isAdmin && !isSuperAdmin"
              max-width="12"
              :src="sidebarImages[index]"
            >
            </v-img>
          </v-list-item-icon>
          <v-list-item-content class="text-subtitle-1 font-weight-bold">{{
            item.name
          }}</v-list-item-content>
        </v-list-item>

        <!-- <v-divider class="mt-8 mb-8 ml-6 mr-6"></v-divider> -->
      </v-list>
    </v-navigation-drawer>
    <!-- End of mobile side menu -->
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
import ProfileMenu from "./ProfileMenu.vue";
export default {
  name: "TopNav",
  props: {
    isAuthenticated: Boolean,
    isAdmin: {},
    isSuperAdmin: {},
    getUser: {},
    isPendingInvitation: {},
    venueId: {},
  },
  components: { ProfileMenu },

  data() {
    return {
      drawer: false, // Hide mobile side menu by default
      navDropDownOptionsMobile: [],
      navDropDownOptions: [],
      sidebarImages: [
        require("../assets/img/HomeSideBarIcon.svg"),
        require("../assets/img/NewOrderSideBarIcon.svg"),
        require("../assets/img/CurrentOrderSideBarIcon.svg"),
      ],
      adminRouteURL: `admin/${this.venueId}/restaurant`,
    };
  },
  created() {
    this.adminRouteURL = `admin/${this.venueId}/restaurant`;
    this.setNavDropDownOptions();
  },
  mounted() {},
  methods: {
    logout() {
      // console.log("logout");
    },
    setNavDropDownOptions() {
      this.navDropDownOptions = [
        {
          id: 0,
          name: this.$t("navDropDown.home"),
          path: "/",
          show: !this.isSuperAdmin,
        },
        {
          id: 1,
          name: this.$t("navDropDown.admin"),
          path: this.getAdminRoute,
          show: this.isAdmin && !this.isRouteAdmin,
        },
        {
          id: 2,
          name: this.isAdmin
            ? this.$t("sidebarLinks.adminRestaurant")
            : this.$t("sidebarLinks.adminRestaurants"),
          path: this.isAdmin
            ? `/admin/${this.venueId}/restaurant`
            : "/admin/restaurants",
          show:
            (this.isAdmin || this.isSuperAdmin) &&
            this.isRouteAdmin &&
            this.$isMobile,
        },
        {
          id: 3,
          name: this.$t("sidebarLinks.adminSuppliers"),
          path: "/admin/suppliers",
          show: this.isSuperAdmin && this.isRouteAdmin && this.$isMobile,
        },
        {
          id: 4,
          name: this.$t("navDropDown.userProfile"),
          path: "/profile",
          show: !this.isSuperAdmin && !this.isRouteAdmin,
        },
        {
          id: 5,
          name: this.$t("sidebarLinks.adminUsers"),
          path: `/admin/users`,
          show:
            this.isRouteAdmin &&
            (this.isAdmin || this.isSuperAdmin) &&
            this.$isMobile,
        },
        {
          id: 6,
          name: this.$t("sidebarLinks.adminStations"),
          path: `/admin/${this.venueId}/stations`,
          show: this.isRouteAdmin && this.isAdmin && this.$isMobile,
        },
        {
          id: 7,
          name: this.$t("sidebarLinks.adminArticles"),
          path: `/admin/articles`,
          show: this.isRouteAdmin && this.isSuperAdmin && this.$isMobile,
        },
        {
          id: 8,
          name: this.$t("navDropDown.articles"),
          path: "/articles",
          show: !this.isSuperAdmin && !this.isRouteAdmin,
        },
        {
          id: 9,
          name: this.$t("navDropDown.suppliers"),
          path: "/suppliers",
          show: !this.isSuperAdmin && !this.isRouteAdmin,
        },
        {
          id: 10,
          name: this.$t("navDropDown.help"),
          path: "/help",
          show: !this.isRouteAdmin,
        },
        {
          id: 11,
          name: this.$t("sidebarLinks.adminReports"),
          path: `/admin/reports`,
          show: this.isRouteAdmin && this.isAdmin,
        },
        {
          id: 12,
          name: this.$t("navDropDown.logout"),
          path: "/logout",
          show: true,
        },
      ];
    },
    goDashboard() {
      this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters({
      userProfile: "userProfile/getUserProfile",
    }),
    isRouteAdmin() {
      return this.$route.path.includes("admin");
    },
    getAdminRoute() {
      return this.adminRouteURL;
    },
    getUserProfileStatus() {
      return this.userProfileStatus;
    },
    getCurrentRoute() {
      return this.$route.path;
    },
    profile() {
      return this.userProfile;
    },
  },
  watch: {
    isAdmin() {
      this.$forceUpdate();
    },
    isSuperAdmin() {
      this.$forceUpdate();
    },
    isRouteAdmin() {
      this.setNavDropDownOptions();
      this.$forceUpdate();
    },
    getUser() {
      this.setNavDropDownOptions();
      this.$forceUpdate();
    },
    venueId() {
      this.adminRouteURL = `/admin/${this.venueId}/restaurant`;
      this.setNavDropDownOptions();
    },
    getCurrentRoute() {
      // console.log("ROUTE", this.$route.path);
    },
  },
};
</script>
<style lang="scss" scoped>
.app-bar-box-shadow {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04) !important;
}
.adjust-logo-margin {
}
.icon-margin-adjust {
  margin-top: 18px;
}
.logout-item {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.text-align-center {
  justify-content: center;
}
.width100 {
  width: 100%;
}
.border-bottom {
  border-bottom: 1px solid #a6a6a6;
  margin-bottom: 8px;
}
</style>
