<template>
  <v-app class="main-app">
    <top-nav
      :isAuthenticated="isLoggedIn"
      v-if="showTopNav"
      :isAdmin="getIsUserAdmin"
      :isSuperAdmin="isUserSuperAdmin"
      :getUser="getUser"
      :isPendingInvitation="isPendingInvitation"
      :venueId="getVenueData && getVenueData.id"
    ></top-nav>
    <div>
      <side-bar
        class="d-none d-md-block"
        v-if="!isPendingInvitation && isLoggedIn && !getCurrentPagePath"
        :links="sidebarLinks"
        :isAdmin="getIsUserAdmin"
        :isSuperAdmin="isUserSuperAdmin"
      ></side-bar>
      <v-main
        class="content"
        :class="{
          contentWithSidebar:
            !isPendingInvitation && isLoggedIn && !getCurrentPagePath,
        }"
      >
        <router-view
          :isAuthenticated="isLoggedIn"
          :user="user"
          :userProfile="userProfile"
          :isSuperAdmin="isUserSuperAdmin"
          :isAdmin="getIsUserAdmin"
          :venue="getVenueData"
          :isFreePlan="isFreePlan"
          :isBasicPlan="isBasicPlan"
          :isProPlan="isProPlan"
          :isTrialEnd="isTrialEnd"
          :noSubscriptionSet="noSubscriptionSet"
          :currentSubscriptionPlan="currentSubscriptionPlan"
          :isProOrFreeTrial="isProOrFreeTrial"
        ></router-view>
      </v-main>
    </div>
    <loader-component v-if="showLoader"></loader-component>
    <notification-component
      :showSnackbar="snackbar"
      :text="getSnackBarText"
      :timeout="snackBarTimeout"
      @close="snackbar = false"
    ></notification-component>
  </v-app>
</template>

<script>
import TopNav from "./components/TopNav.vue";
import SideBar from "./components/Sidebar.vue";
import { mapGetters } from "vuex";
import EventBus from "./shared/EventBus";
import LoaderComponent from "./components/LoaderComponent.vue";
import NotificationComponent from "./components/NotificationComponent.vue";
import { REQUEST_SUCCESS } from "./shared/statuses";

export default {
  name: "App",
  components: {
    SideBar,
    TopNav,
    LoaderComponent,
    NotificationComponent,
  },
  data() {
    return {
      sidebarLinks: [],
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 3000,
      restaurantID: null,
    };
  },
  created() {
    this.setSideBarLinks();
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
  mounted() {
    this.restaurantID = parseInt(this.$route.params.id);
    EventBus.on("logout", () => {
      this.logOut();
    });
    EventBus.on("showSnackbar", (data) => {
      this.showSnackBarAction(data);
    });
    this.isLoggedIn && this.$store.dispatch("loader/setLoadingState");

    this.isLoggedIn &&
      this.$store.dispatch("userProfile/getUserProfile").then(
        () => {
          this.$store.dispatch("loader/setLoadingState");
          this.$i18n.locale = this.userProfile.language;
        },
        () => {
          this.$store.dispatch("loader/setLoadingState");
        }
      );
  },
  beforeDestroy() {
    EventBus.remove("logout");
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    showSnackBarAction(data) {
      this.snackBarText = data.text;
      this.snackBarTimeout = data.timeout || 3000;
      this.snackbar = true;
    },
    setSideBarLinks() {
      this.sidebarLinks = [
        {
          id: 0,
          name: this.$t("sidebarLinks.home"),
          path: "/",
          show: !this.isRouteAdmin,
        },
        {
          id: 1,
          name: this.getIsUserAdmin
            ? this.$t("sidebarLinks.adminRestaurant")
            : this.$t("sidebarLinks.adminRestaurants"),
          path: this.getIsUserAdmin
            ? `/admin/${this.getVenueData.id}/restaurant`
            : "/admin/restaurants",
          show:
            this.isRouteAdmin &&
            (this.getIsUserAdmin || this.getIsUserSuperAdmin),
        },
        {
          id: 2,
          name: this.$t("sidebarLinks.adminSuppliers"),
          path: "/admin/suppliers",
          show: this.isRouteAdmin && this.isUserSuperAdmin,
        },
        {
          id: 3,
          name: this.$t("sidebarLinks.adminUsers"),
          path: `/admin/users`,
          show:
            this.isRouteAdmin &&
            (this.getIsUserSuperAdmin || this.getIsUserAdmin),
        },
        {
          id: 4,
          name: this.$t("sidebarLinks.adminStations"),
          path: `/admin/${this.getVenueData && this.getVenueData.id}/stations`,
          show: this.isRouteAdmin && this.getIsUserAdmin,
        },
        {
          id: 5,
          name: this.$t("sidebarLinks.adminArticles"),
          path: `/admin/articles`,
          show: this.isRouteAdmin && this.getIsUserSuperAdmin,
        },
        {
          id: 6,
          name: this.$t("sidebarLinks.adminReports"),
          path: `/admin/reports`,
          show: this.isRouteAdmin && this.getIsUserAdmin,
        },
      ];
    },
  },

  computed: {
    ...mapGetters({
      isUserAuthenticated: "auth/isUserAuthenticated",
      getUser: "auth/getUser",
      isLoading: "loader/isLoading",
      getVenue: "userProfile/getVenue",
      isUserSuperAdmin: "auth/isUserSuperAdmin",
      isUserAdmin: "auth/isUserAdmin",
      getUserProfile: "userProfile/getUserProfile",
      getUserProfileStatus: "userProfile/getUserProfileStatus",
      subscription: "subscription/getVenueSubscription",
      getDaysTillTrialPlanEnds: "subscription/getDaysTillTrialPlanEnds",
      trialPlanEnded: "subscription/trialPlanEnded",
      getSettingSubscriptionStatus: "subscription/getSettingSubscriptionStatus",
      freePlan: "subscription/isFreePlan",
      basicPlan: "subscription/isBasicPlan",
      proPlan: "subscription/isProPlan",
      proOrFreeTrial: "subscription/isProOrFreeTrialPlan",
    }),
    isLoggedIn() {
      return this.isUserAuthenticated;
    },
    user() {
      return this.getUser;
    },
    getCurrentPagePath() {
      return (
        this.$route.path.includes("new-order") ||
        this.$route.path.includes("new-custom-order") ||
        this.$route.path.includes("edit-order") ||
        this.$route.path.includes("login") ||
        this.$route.path.includes("sign-up")
      );
    },
    // getIsMobile() {
    //   return this.$isMobile;
    // },
    showTopNav() {
      if (this.$isMobile && this.getCurrentPagePath) {
        return false;
      }
      return true;
    },
    showLoader() {
      return this.isLoading;
    },
    getSnackBarText() {
      return this.snackBarText;
    },
    getVenueData() {
      return this.getVenue;
    },
    isRouteAdmin() {
      return this.$route.path.includes("admin");
    },
    getIsUserAdmin() {
      return this.isUserAdmin;
    },
    getIsUserSuperAdmin() {
      return this.isUserSuperAdmin;
    },
    userProfile() {
      return this.getUserProfile;
    },
    isPendingInvitation() {
      return (
        this.getUserProfile &&
        this.getUserProfile.pending_invites &&
        this.getUserProfile.pending_invites.length > 0
      );
    },
    isFreePlan() {
      return this.freePlan;
    },
    isBasicPlan() {
      return this.basicPlan;
    },
    isProPlan() {
      return this.proPlan;
    },
    isTrialEnd() {
      return this.trialPlanEnded;
    },
    timeTillTrialEnds() {
      return this.getDaysTillTrialPlanEnds;
    },
    noSubscriptionSet() {
      return !this.subscription;
    },
    currentSubscriptionPlan() {
      return this.subscription?.plan;
    },
    isProOrFreeTrial() {
      return this.proOrFreeTrial;
    },
  },
  watch: {
    isRouteAdmin() {
      this.setSideBarLinks();
    },
    getVenueData() {
      this.setSideBarLinks();
    },
    getUserProfileStatus() {
      this.getUserProfileStatus === REQUEST_SUCCESS &&
        this.isLoggedIn &&
        !this.isUserSuperAdmin &&
        this.getVenueData &&
        this.$store.dispatch(
          "subscription/getVenueSubscription",
          this.getVenue?.id
        );
    },
    isLoggedIn() {
      this.isLoggedIn && this.$store.dispatch("loader/setLoadingState");

      this.isLoggedIn &&
        this.$store.dispatch("userProfile/getUserProfile").then(
          () => {
            this.$store.dispatch("loader/setLoadingState");
            this.$i18n.locale = this.userProfile.language;
          },
          () => {
            this.$store.dispatch("loader/setLoadingState");
          }
        );
    },
  },
};
</script>
<style lang="scss" scoped>
.main-app {
  display: flex;
  flex-direction: column;
}
@media screen and(max-width: 959px) {
  .content {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
.content {
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
}
.contentWithSidebar {
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
  width: calc(100% - 284px);
  margin-left: 284px;
  padding: 0 !important;
  padding: 32px 0 !important;
}
.refresh-container {
  width: 15%;
  position: fixed;
  bottom: 10px;
  right: 15px;
  background-color: #fff;
  padding: 25px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
}
.close-refresh-modal {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
}
</style>
